import { StyledPrimaryButton } from "@/components/uielements/primaryButton/styles";

interface ButtonProps {
  children: any;
  // TODO: improve this type and remove string type to narrow it to proper union type
  theme: string;
  type: "button" | "submit" | "reset";
  submitting?: boolean;
  minWidth?: number;
  handleClick?: () => void;
  disabled?: boolean;
}

const PrimaryButton: React.SFC<ButtonProps> = ({
  children,
  theme,
  type,
  submitting,
  handleClick,
  minWidth,
  disabled,
}) => (
  <StyledPrimaryButton
    buttonTheme={theme}
    type={type}
    submitting={submitting}
    minWidth={minWidth}
    onClick={handleClick}
    disabled={disabled}
  >
    {children}
  </StyledPrimaryButton>
);

export default PrimaryButton;
