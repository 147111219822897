import { Component, Children, cloneElement } from "react";
import { FixedContainer } from "./styles";

interface Props {
  id: string;
  fromTop: number;
  minWidth?: number;
}

interface State {
  fixed: boolean;
  elementHeight: any;
}

export default class FixOnScroll extends Component<Props, State> {
  state = {
    elementHeight: null,
    fixed: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    // Get 'fixed' element in order to derive height
    const element = document.getElementById(this.props.id);

    // Get 'fixed' elements' static container to derive position from top
    const elementContainer = document.getElementById(
      `${this.props.id}Container`
    );

    if (element && elementContainer) {
      const elementHeight = element.offsetHeight;
      const pos = elementContainer.getBoundingClientRect();
      const fixed =
        (this.props.minWidth || 9999) >= window.innerWidth &&
        pos.top < this.props.fromTop;

      this.setState({
        elementHeight,
        fixed,
      });
    }
  };

  render() {
    const { children, fromTop, id } = this.props;
    const { fixed, elementHeight } = this.state;

    // Attach fixed status to child component
    const childrenWithProps = Children.map(children, (child: any) =>
      cloneElement(child, { fixed })
    );

    return (
      <div
        id={`${id}Container`}
        style={{ height: (fixed && elementHeight) || "auto" }}
      >
        <FixedContainer fromTop={fromTop} fixed={fixed} id={id}>
          {childrenWithProps}
        </FixedContainer>
      </div>
    );
  }
}
