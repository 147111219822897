import { type WebConfig } from "@koala/sdk";
import { withTheme } from "styled-components";

interface Props {
  color?: string;
  size?: number;
}

interface ThemeProps {
  theme: WebConfig;
}

const _HistoryIconSvg = ({ color, size = 24, theme }: Props & ThemeProps) => {
  const fallbackColor = theme.text.primary_font.color ?? "inherit";
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.925 2.843a9.096 9.096 0 0 0-8.769 6.845l-.609-.921a.832.832 0 1 0-1.389.915l1.832 2.776a.902.902 0 0 0 .528.354c.217.043.443 0 .629-.121l2.747-1.85a.832.832 0 1 0-.928-1.382l-1.25.843a7.416 7.416 0 1 1 7.21 9.19 7.402 7.402 0 0 1-6.062-3.182.833.833 0 1 0-1.365.952A9.058 9.058 0 0 0 22 12a9.127 9.127 0 0 0-9.073-9.157Z"
        fill={color ?? fallbackColor}
      />
      <path
        d="M12.842 6.963a.833.833 0 0 0-.832.833V12c.003.22.09.43.244.588l2.497 2.52a.844.844 0 0 0 1.177.006.832.832 0 0 0 .005-1.177l-2.258-2.28V7.797a.833.833 0 0 0-.833-.833Z"
        fill={color ?? fallbackColor}
      />
    </svg>
  );
};

export const HistoryIconSvg = withTheme(_HistoryIconSvg);
