import styled from "styled-components";
import { StyledReorderHeading } from "../list/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";

export const StyledNewOrderHeading = styled(StyledReorderHeading)(
  ({ theme }) => ({
    ...getFontStyles(theme.location_search.tertiary_font, [
      "color",
      "font_family",
      "font_size",
    ]),
  })
);
