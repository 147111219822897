import { type Location } from "@koala/sdk";
import { useState } from "react";
import { LocationCard } from "../../card";
import {
  StyledLocationGroup,
  StyledLocationGroupLocations,
  StyledLocationGroupTrigger,
} from "./styles";
import {
  canadianProvinceAbbrMappings,
  stateAbbrMappings,
} from "@/constants/states";
import { type ILocationList } from "@/types/locations";
import { genericGroupItemsByKey } from "@/utils/global";
import { splitLocationsByCountry } from "@/utils/locations";

/**
 * Renders a brand's locations grouped by US State or CA Province.
 *
 * @TODO change genericGroupItemsByKey to a Map lookup to reduce o^n complexity.
 * @TODO consume `setActiveLocation` at the `LocationCard` level to eliminate
 * prop drilling.
 * @TODO DRY out card rendering per-country.
 */
function GroupedLocationList({
  locations,
  setActiveLocation,
  activeLocationId,
  menuCta,
}: ILocationList) {
  const [expandedGroupName, setExpandedGroupId] = useState<string | null>(null);

  // Split locations by country—Canada or USA.
  const locationsByCountry = splitLocationsByCountry(locations);

  // Group US locations by state and sort alphabetically.
  const { filtersArray: statesArray, filtersObject: statesObject } =
    genericGroupItemsByKey(locationsByCountry.US, "cached_data.state");

  // Group US locations by province and sort alphabetically.
  const { filtersArray: provincesArray, filtersObject: provincesObject } =
    genericGroupItemsByKey(locationsByCountry.CA, "cached_data.state");

  // Expands or collapses a group of locations in the list.
  function toggleActiveGroup(selectedGroupName: string) {
    setExpandedGroupId((currentGroupName) => {
      // If the clicked group is currently expanded, collapse it.
      if (selectedGroupName === currentGroupName) {
        return null;
      }
      // Otherwise, expand the new group.
      return selectedGroupName;
    });
  }

  return (
    <>
      {statesArray.map((state, stateIndex) => {
        // The group of locations is uniquely identified by the state name.
        // @ts-expect-error `state` string can't index `stateAbbrMappings`.
        const stateName = stateAbbrMappings[state];
        const isExpanded = stateName === expandedGroupName;
        return (
          <StyledLocationGroup key={stateIndex} role="region">
            <StyledLocationGroupTrigger
              isActive={isExpanded}
              onClick={() => toggleActiveGroup(stateName)}
              aria-expanded={isExpanded}
            >
              {stateName}
            </StyledLocationGroupTrigger>
            {isExpanded && (
              <StyledLocationGroupLocations>
                {/** @ts-expect-error `state` can't index `statesObject`. */}
                {statesObject[state].map((location: Location) => (
                  <LocationCard
                    key={location.id}
                    activeLocationId={activeLocationId}
                    location={location}
                    setActiveLocation={setActiveLocation}
                    menuCta={menuCta}
                  />
                ))}
              </StyledLocationGroupLocations>
            )}
          </StyledLocationGroup>
        );
      })}
      {provincesArray.map((state: string) => {
        // The group of locations is uniquely identified by the province name.
        // @ts-expect-error `state` string can't index `canadianProvinceAbbrMappings`.
        const provinceName = canadianProvinceAbbrMappings[state];
        const isExpanded = provinceName === expandedGroupName;
        return (
          <StyledLocationGroup key={provinceName} role="region">
            <StyledLocationGroupTrigger
              isActive={isExpanded}
              onClick={() => toggleActiveGroup(provinceName)}
              aria-expanded={isExpanded}
            >
              {provinceName}
            </StyledLocationGroupTrigger>
            {isExpanded && (
              <StyledLocationGroupLocations>
                {/** @ts-expect-error `state` can't index `provincesObject`. */}
                {provincesObject[state].map((location: Location) => (
                  <LocationCard
                    key={location.id}
                    activeLocationId={activeLocationId}
                    location={location}
                    setActiveLocation={setActiveLocation}
                    menuCta={menuCta}
                  />
                ))}
              </StyledLocationGroupLocations>
            )}
          </StyledLocationGroup>
        );
      })}
    </>
  );
}

export default GroupedLocationList;
