import { getContrast, transparentize } from "polished";
import styled from "styled-components";
import { COLORS, LAYOUT } from "@/constants/styles";
import { safePolished } from "@/utils/safePolished";

export const StyledLocationsRewardsSummary = styled.div`
  background: ${({ theme }) =>
    safePolished(transparentize)("0.9", theme.global.primary_active_color)};
  border-radius: ${({ theme }) => theme.global.border_radius}px;
  padding: ${LAYOUT.GUTTER}px;
  display: flex;
  align-items: center;

  div {
    margin: 0;
  }

  svg,
  img {
    height: 56px;
    width: 56px;
    margin-right: ${LAYOUT.GUTTER}px;
    min-width: 10%;

    @media (max-width: 767px) {
      display: none;
    }
  }
`;

export const StyledRewardsSummaryHeader = styled.h2`
  font-family: ${({ theme }) => theme.primary_font.bold.font_family};
  font-size: 16px;
  margin: 0;
`;

export const StyledButtonContainer = styled.p`
  display: flex;
  justify-content: flex-start;
  line-height: 1.5;
  margin: 0;

  button,
  a {
    text-align: left;
    font-size: 14px;
    margin: ${LAYOUT.GUTTER / 4}px 0 0 0;
    color: ${({ theme }) => theme.global.primary_active_color};

    // If contrast is not high enough
    ${({ theme }) =>
      getContrast(COLORS.WHITE, theme.global.primary_active_color) < 4.5 &&
      `
      color: ${theme.text.primary_font.color ?? "inherit"};
      text-decoration: underline;
    `};

    &:nth-of-type(even) {
      margin-left: ${LAYOUT.GUTTER / 2}px;
    }

    &:nth-of-type(odd) {
      margin-right: ${LAYOUT.GUTTER / 2}px;
    }
  }
`;
