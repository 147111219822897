import styled from "styled-components";

export const FixedContainer = styled.div`
  ${(props: { fromTop: any; fixed: any }) =>
    props.fixed &&
    `
    left: 0;
    position: fixed;
    top: ${props.fromTop}px;
    width: 100%;
    z-index: 1;
  `}
`;
