import { type Location } from "@koala/sdk";
import { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import GroupedList from "@/components/locations/results/grouped/index";
import List from "@/components/locations/results/list/index";
import { LOCATION_VIEW_STATES, LOCATIONS_CONFIG } from "@/constants/locations";
import { type RootState } from "@/types/app";
import { safelyGetConfig } from "@/utils/stringHelpers";

interface Props extends ReduxProps {
  displayType: string;
  activeLocationId?: number;
  locations: Location[];
  setActiveLocation: (id: number) => void;
  menuCta?: string;
}

class LocationResults extends Component<Props> {
  render() {
    const {
      activeLocationId,
      displayType,
      locations,
      setActiveLocation,
      menuCta,
      webConfig,
    } = this.props;

    const listDisplay = safelyGetConfig(webConfig, "locations.list_display");

    /*
      A 'grouped' results list should only display if a) the config has enabled it and b) we are in 'view_all' mode.
      Otherwise, we should display a normal list
    */
    if (
      listDisplay === LOCATIONS_CONFIG.ALL_DISPLAY.GROUP_BY_STATE &&
      displayType === LOCATION_VIEW_STATES.VIEW_ALL
    ) {
      return (
        <GroupedList
          activeLocationId={activeLocationId}
          locations={locations}
          setActiveLocation={setActiveLocation}
          menuCta={menuCta}
        />
      );
    }

    return (
      <List
        activeLocationId={activeLocationId}
        locations={locations}
        setActiveLocation={setActiveLocation}
        menuCta={menuCta}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  webConfig: state.app.cmsConfig.webConfig,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(LocationResults);
