import { type WebConfig } from "@koala/sdk";
import { Component } from "react";
import { type ConnectedProps, connect } from "react-redux";
import { compose } from "redux";
import { Field, type InjectedFormProps, reduxForm } from "redux-form";
import { withTheme } from "styled-components";
import {
  StyledFindOrgStoreText,
  StyledForm,
  StyledLocationIcon,
  StyledOrUseLocationText,
  StyledSearch,
  StyledSubmit,
  StyledUseMyCurrentLocation,
  StyledViewAll,
  StyledViewAllWrapper,
} from "./styles";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { SearchInput } from "@/components/uielements/searchInput";
import { CSS_CLASSES } from "@/constants/cssClassNames";
import { K_ANALYTICS_EVENTS } from "@/constants/events";
import { LOCATION_OPTIONS } from "@/constants/locations";
import { type RootState } from "@/types/app";
import { type LocationsParams } from "@/types/locations";
import { getUserLocation } from "@/utils/geolocate";
import { fireGaEvent, gaActions, gaCats } from "@/utils/googleAnalytics";
import { fireKAnalyticsEvent } from "@/utils/koalaAnalytics";
import { safelyGetString } from "@/utils/stringHelpers";
import Icon from "@/components/uielements/icon";
import { StyledHr } from "@/components/uielements/hr";
import { type IOrganizationState } from "@/types/organization";

interface ThemeProps {
  theme: WebConfig;
}

interface Props extends ReduxProps, ThemeProps, InjectedFormProps<any, Props> {
  fixed?: boolean;
  loading: boolean;
  params?: LocationsParams;
  showViewAll: boolean;
  onReset?: () => void;
  onSearch: (values?: object) => void;
  getNearbyLocations: () => void;
  organization: IOrganizationState;
  isLocationListEmpty: boolean;
  isGeoIpEnabled: boolean;
}

class Search extends Component<Props> {
  componentDidUpdate(prevProps: { params?: object }) {
    // Reset form if params don't exist (e.g. after clearing a search)
    // TODO - probably a better way to do this
    if (prevProps.params && !this.props.params) {
      this.props.reset();
    }
  }

  getUserLocation = () => {
    const { onSearch, isGeoIpEnabled } = this.props;
    // TODO - this should be move into REDUX so we can use the loading state
    getUserLocation()
      .then(({ latitude, longitude }: any) => {
        onSearch({
          distance: LOCATION_OPTIONS.defaultSearchRadius,
          latitude,
          longitude,
        });

        // KA Event for explicit geolocation searches only
        fireKAnalyticsEvent(K_ANALYTICS_EVENTS.GEOLOCATION_SEARCH, {
          name: `${latitude}, ${longitude}`,
        });
        fireGaEvent(gaCats.findShop, gaActions.geolocationSearch);
      })
      .catch(() => {
        if (isGeoIpEnabled) {
          this.props.getNearbyLocations();
        }
      });
  };

  render() {
    const {
      handleSubmit,
      onSearch,
      onReset,
      showViewAll,
      fixed,
      loading,
      strings,
      organization,
      isLocationListEmpty,
      isGeoIpEnabled,
    } = this.props;

    return (
      <>
        {/** @ts-expect-error ensure that `fixed` is defined. */}
        <StyledSearch fixed={fixed}>
          {/* Default to Pickup Search */}
          <StyledForm
            onSubmit={handleSubmit((values: object) => onSearch(values))}
          >
            <div>
              {/* Search Input */}
              <Field
                name="address"
                placeholder={safelyGetString(
                  strings,
                  "locations.search_placeholder"
                )}
                component={SearchInput}
                {...{
                  icon: "/static/img/icons/search.svg",
                }}
              />

              {/* Use My Location Button */}
              <StyledLocationIcon
                type="button"
                onClick={this.getUserLocation}
                aria-label="Search by my current location"
              >
                <img
                  src="/static/img/icons/locationArrow.svg"
                  alt="Use my location icon"
                />
              </StyledLocationIcon>
            </div>

            {/* Submit Button */}
            <StyledSubmit
              type="submit"
              id="Search__search-btn"
              data-testid="search-address-submit-button"
              size="small"
            >
              <StringAccessor
                accessor={
                  loading
                    ? "locations.search_cta_loading"
                    : "locations.search_cta"
                }
              />
            </StyledSubmit>
          </StyledForm>
          {isLocationListEmpty && !isGeoIpEnabled && (
            <>
              <StyledFindOrgStoreText className="bold">
                <StringAccessor
                  accessor="locations.search_start_cta"
                  dataObj={{ brandName: organization.label }}
                  html={true}
                />
              </StyledFindOrgStoreText>
              <StyledOrUseLocationText>&nbsp;or&nbsp;</StyledOrUseLocationText>
              <StyledUseMyCurrentLocation
                type="button"
                onClick={this.getUserLocation}
              >
                <a>
                  Use my current location&nbsp;
                  <Icon.Crosshair title="location" />
                </a>
              </StyledUseMyCurrentLocation>
            </>
          )}
          {/* View All Locations button only if you're not already viewing all */}
          {showViewAll && (
            <>
              <StyledHr />
              <StyledViewAllWrapper>
                <StyledViewAll
                  data-testid="view-all-locations-link"
                  onClick={onReset}
                  className={CSS_CLASSES.STORE_LOCATOR.VIEW_ALL_LOCATION_LINK}
                >
                  <StringAccessor accessor="locations.all_locations_cta" />
                </StyledViewAll>
              </StyledViewAllWrapper>
            </>
          )}
        </StyledSearch>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  strings: state.app.cmsConfig.strings,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default compose<any>(
  withTheme,
  connector,
  reduxForm<any, Props>({
    form: "locations-search",
    validate: (values: { address: string }) => {
      const errors: any = {};
      if (!values.address) {
        errors.address = "Zip code or address required";
      }
      return errors;
    },
  })
)(Search);
