export function getUserLocation() {
  return new Promise((resolve, reject) => {
    function position(location: any) {
      resolve(location.coords);
    }

    function denied(errorObj: { code: number }) {
      let msg =
        "The Geolocation service failed. Please try manually searching instead.";
      if (errorObj.code === 1) {
        msg = "You have blocked location services on this browser.";
      }
      reject(msg);
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position, denied);
    }
  });
}
