import { type WebConfig } from "@koala/sdk";
import { Component } from "react";
import { type ConnectedProps, connect } from "react-redux";
import { compose } from "redux";
import { type InjectedFormProps, reduxForm } from "redux-form";
import { withTheme } from "styled-components";
import { DeliverySearchStoredAddresses } from "../storedAddresses";
import RadarAutocomplete from "@/components/fulfillmentManager/deliveryManager/deliveryForm/autocomplete";
import GoogleAutocomplete from "@/components/fulfillmentManager/deliveryManager/deliveryForm/googautocomplete";
import { locationsActions } from "@/redux/locations/actions";
import { type RootState } from "@/types/app";

interface ThemeProps {
  theme: WebConfig;
}

interface Props extends ReduxProps, ThemeProps, InjectedFormProps<any, Props> {
  loading: boolean;
}
class DeliverySearch extends Component<Props> {
  render() {
    const { loading, theme, searchDeliveryCoverage } = this.props;
    const AutocompleteComponent = theme.global.radar_maps
      ? RadarAutocomplete
      : GoogleAutocomplete;

    return (
      <>
        <AutocompleteComponent
          loading={loading}
          apiKey={theme.locations.map_key}
          checkOrSearchDeliveryCoverage={searchDeliveryCoverage}
        />
        <DeliverySearchStoredAddresses />
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  loading: state.app.locations.searchLoading,
});

const mapDispatchToProps = {
  searchDeliveryCoverage: locationsActions.searchDeliveryCoverage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default compose<any>(
  withTheme,
  connector,
  reduxForm<any, Props>({ form: "delivery-search" })
)(DeliverySearch);
