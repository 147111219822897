import { type Location } from "@koala/sdk";
import * as React from "react";
import { LocationCard } from "@/components/locations/card";
import { type ILocationList } from "@/types/locations";

export default class List extends React.Component<ILocationList> {
  render() {
    const { locations, setActiveLocation, activeLocationId, menuCta } =
      this.props;
    return (
      <>
        {/* Results list */}
        {locations &&
          locations.length > 0 &&
          locations.map((location: Location) => (
            <LocationCard
              key={location.id}
              activeLocationId={activeLocationId}
              location={location}
              setActiveLocation={setActiveLocation}
              menuCta={menuCta}
            />
          ))}
      </>
    );
  }
}
