import { CONVEYANCE_TYPES, type OrderDetails } from "@koala/sdk";
import {
  StyledReorderListItem,
  StyledReorderDate,
  StyledReorderConveyanceLabel,
  StyledReorderLocationLabel,
  StyledReorderMenuItems,
  StyledReorderButton,
  ReorderHeader,
} from "./styles";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { DATE_FORMAT } from "@/constants/dates";
import { API_FOR_CONVEYANCE_TYPES_SWAP } from "@/constants/global";
import { deriveBasketItemsList } from "@/utils/basket";
import { formatDate } from "@/utils/dates";

interface Props {
  orderDetails: OrderDetails;
  showReorderReceipt: (orderDetails: OrderDetails) => void;
}

export const ReorderListItem = ({
  orderDetails,
  showReorderReceipt,
}: Props) => {
  const orderConveyance =
    API_FOR_CONVEYANCE_TYPES_SWAP[
      orderDetails.order_data.basket.conveyance_type.type
    ] || CONVEYANCE_TYPES.PICKUP;

  return (
    <StyledReorderListItem>
      <ReorderHeader>
        <StyledReorderDate>
          {formatDate(
            orderDetails.order_data.order.ordered_at,
            DATE_FORMAT.SHORT_WRITTEN_DATE
          )}
        </StyledReorderDate>
        <StyledReorderConveyanceLabel>
          {orderConveyance}
        </StyledReorderConveyanceLabel>
      </ReorderHeader>

      <StyledReorderLocationLabel>
        {orderDetails.store_location?.label}
      </StyledReorderLocationLabel>

      <StyledReorderMenuItems>
        <p>
          {/* @ts-expect-error TODO: update type of function */}
          {deriveBasketItemsList(orderDetails.order_data.basket.basket_items)}
        </p>
      </StyledReorderMenuItems>

      <StyledReorderButton
        buttonTheme="primary"
        submitting={false}
        onClick={() => {
          showReorderReceipt(orderDetails);
        }}
        aria-label={`Reorder order #${orderDetails.order_data.order.order_number}`}
      >
        <StringAccessor accessor="reorders.reorder_cta" />
      </StyledReorderButton>
    </StyledReorderListItem>
  );
};
