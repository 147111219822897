import { COMPONENT_CONFIG } from "@/brand-components";
import { Modal } from "@/components/uielements/modal";
import { useSelector } from "@/redux";

export function Terms() {
  const { slug } = useSelector((state) => state.app.organization.organization);
  const REWARDS_TERMS_CONTENT = COMPONENT_CONFIG.MODAL_HOW_REWARDS_WORK[slug];

  return (
    <>
      <Modal.Title>How Rewards Work</Modal.Title>
      <Modal.Content>
        {REWARDS_TERMS_CONTENT && <REWARDS_TERMS_CONTENT />}
      </Modal.Content>
      <Modal.Footer>
        <Modal.Cancel>Close</Modal.Cancel>
      </Modal.Footer>
    </>
  );
}
