import styled, { type StyledProps } from "styled-components";
import { StyledPrimaryButton } from "@/components/uielements/primaryButton/styles";
import { LAYOUT } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { getButtonThemeProperties } from "@/components/uielements/primaryButton/utils";

interface StyledSearchProps {
  fixed: boolean;
}

export const StyledSearch = styled.div<StyledSearchProps>`
  padding: ${LAYOUT.GUTTER / 2}px 0 0;

  ${({ fixed, theme }) =>
    fixed &&
    `
    background: ${theme.global.body_color};
    border-bottom: 1px solid ${theme.global.primary_border_color};
    padding-left: ${LAYOUT.GUTTER}px;
    padding-right: ${LAYOUT.GUTTER}px;
  `}
`;

export const StyledForm = styled.form`
  display: flex;
  position: relative;

  > div {
    position: relative;
    flex-basis: 70%;
  }
`;

export const StyledSubmit = styled(StyledPrimaryButton)`
  flex-basis: 30%;
  margin-left: ${LAYOUT.GUTTER / 2}px;

  @media (max-width: 767px) {
    border-radius: ${({ theme }: StyledProps<{}>) =>
      `0 ${theme.buttons.primary_button_border_radius}px ${theme.buttons.primary_button_border_radius}px 0`};
    margin-left: 0px;
  }
`;

export const StyledLocationIcon = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 40px;
  padding: 0 7px 0 10px;
  position: absolute;
  right: 0;
  top: 0;
  img {
    height: 20px;
  }
`;

export const StyledViewAllWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${LAYOUT.GUTTER}px 0;
`;

export const StyledViewAll = styled.button(({ theme }) => ({
  background: theme.buttons.knockout_button_background_color,
  color: theme.buttons.knockout_button_text_color,
  cursor: "pointer",
  fontFamily: theme.primary_font.medium.font_family,
  padding: `0 ${LAYOUT.GUTTER}px`,
  height: theme.forms.field_height,
  minWidth: "100px",
  ...getFontStyles(theme.text.primary_font, ["color"]),
  ...getButtonThemeProperties("knockout", theme),
}));

export const StyledFindOrgStoreText = styled.p(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  fontFamily: theme.primary_font.bold.font_family,
  fontWeight: theme.primary_font.bold.font_weight,
  fontSize: theme.primary_font.bold.font_size,
}));

export const StyledOrUseLocationText = styled.p(({ theme }) => ({
  "::after": {
    content: "'\x00a0\x00a0\x00a0\x00a0\x00a0'",
    textDecoration: "line-through",
  },
  "::before": {
    content: "'\x00a0\x00a0\x00a0\x00a0\x00a0'",
    textDecoration: "line-through",
  },
  color: "grey",
  display: "flex",
  justifyContent: "center",
  fontFamily: theme.primary_font.medium.font_family,
  fontWeight: theme.primary_font.medium.font_weight,
  fontSize: theme.primary_font.medium.font_size,
}));

export const StyledUseMyCurrentLocation = styled.p(({ theme }) => ({
  a: {
    display: "flex",
    justifyContent: "center",
    color: theme.global.primary_active_color,
    cursor: "pointer",
    fontFamily: theme.primary_font.bold.font_family,
    fontWeight: theme.primary_font.bold.font_weight,
    fontSize: theme.primary_font.bold.font_size,
  },
}));
