import { Component } from "react";
import { StyledAnimatedBlock } from "./styles";

class AnimatedBlockComponent extends Component<{ role?: string }> {
  render() {
    return <StyledAnimatedBlock {...this.props} />;
  }
}

export default AnimatedBlockComponent;
