import { type WebConfig } from "@koala/sdk";
import styled from "styled-components";
import { SPACING_SCALE } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";

interface ThemeProps {
  theme: WebConfig;
}

interface ListProps {
  collapse?: boolean;
}
interface ButtonProps {
  invert?: boolean;
}

const LIST_TRANSITION_TIME = "0.35s";

export const StyledCollapsibleContainer = styled.div`
  max-height: 300px;
  transition: max-height ${LIST_TRANSITION_TIME} ease-in-out, visibility 0s 0s;

  ${(props: ListProps) =>
    props.collapse &&
    `
    max-height: 0px;
    visibility: hidden;
    transition: max-height ${LIST_TRANSITION_TIME} ease-in-out, visibility 0s ${LIST_TRANSITION_TIME};
  `}
`;

export const StyledReorderList = styled.ul`
  display: flex;
  flex-direction: row;
  gap: var(--size-2);
  margin: 0 var(--neg-size-5) 0 var(--neg-size-5);
  padding: 0;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
    height: 0;
    width: 0;
  }
`;

export const StyledReorderHeading = styled.h2(({ theme }) => ({
  margin: 0,
  ...getFontStyles(theme.location_search.tertiary_font, [
    "color",
    "font_family",
    "font_size",
  ]),
}));

export const StyledCollapseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: var(--size-1);
  position: relative;

  &::after {
    content: "";
    display: block;
    position: relative;
    height: ${SPACING_SCALE.XS}px;
    border-bottom: 1.5px solid
      ${(props: ThemeProps) => props.theme.global.primary_active_color};
    border-right: 1.5px solid
      ${(props: ThemeProps) => props.theme.global.primary_active_color};
    transform: rotate(225deg) translateX(-25%) translateY(-25%);
    transition: transform ${LIST_TRANSITION_TIME} ease-in-out;
    width: ${SPACING_SCALE.XS}px;

    ${(props: ButtonProps) =>
      props.invert &&
      `
      transform: rotate(45deg);
    `}
  }
`;

export const ReorderListPadding = styled.li`
  flex: 0 0 var(--size-3);
  height: var(--size-5);
  scroll-snap-align: start;
  list-style-type: none;
  width: var(--size-3);
`;
