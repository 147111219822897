import {
  CONVEYANCE_TYPES,
  type LocationSearchQuery,
  type GeoIp,
} from "@koala/sdk";
import { geoIpLookup } from "@koala/sdk/v4";
import get from "lodash/get";
import dynamic from "next/dynamic";
import { withRouter } from "next/router";
import { Component } from "react";
import { type ConnectedProps, connect } from "react-redux";
import { compose } from "redux";
import { useTheme } from "styled-components";
import { genericEventHandler } from "@/analytics/events";
import { EventNames, GlobalEvents } from "@/analytics/events/constants";
import { LocationsRewardsSummary } from "@/components/account/locationRewardsSummary";
import LoyaltyAccessor from "@/components/account/loyaltyAccessor";
import FixOnScroll from "@/components/app/fixOnScroll";
import Layout from "@/components/app/layout";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { FeatureAccessor } from "@/components/featureAccessor";
import { LocationsHeading } from "@/components/locations/heading";
import {
  LayoutListSection,
  LoadingIndicator,
  LocationsHandoffToggle,
  LocationsHandoffToggleButton,
  LocationsLayout,
  LocationsLayoutList,
  LocationsLayoutMap,
  LocationsNoResults,
  LocationsViewAll,
  LocationsReorders,
  LocationsDeliverySearch,
} from "@/components/locations/layout/styles";
import ResultsList from "@/components/locations/results";
import Search from "@/components/locations/search";
import DeliverySearch from "@/components/locations/search/deliverySearch";
import { ReorderList } from "@/components/reorders/list";
import { NewOrderHeading } from "@/components/reorders/newOrderHeading";
import { Render } from "@/components/uielements/render";
import { CSS_CLASSES } from "@/constants/cssClassNames";
import { FEATURE_FLAGS } from "@/constants/features";
import {
  LOCATION_OPTIONS,
  LOCATION_VIEW_STATES,
  LOCATIONS_CONFIG,
} from "@/constants/locations";
import { LOYALTY_ACCESSOR_TYPES, LOYALTY_FEATURES } from "@/constants/loyalty";
import { LAYOUT } from "@/constants/styles";
import { locationsActions } from "@/redux/locations/actions";
import { createHttpClient } from "@/services/client";
import { type RootState } from "@/types/app";
import { getOrigin } from "@/utils";
import { isAndroidShell } from "@/utils/android";
import * as ErrorReporter from "@/utils/errorReporter";
import { MagicBoxParams } from "@/utils/magicBox";
import { pluralizeString, safelyGetConfig } from "@/utils/stringHelpers";

interface State {
  clickedMapSearch: boolean;
  hideMapButton: boolean;
  viewingAllLocations: boolean;
  handoffMode: CONVEYANCE_TYPES;
}

/**
 * This is a huge hack to fix a hydration mismatch error with React 18.
 * Basically, it forces the Google Map to render client-side rather than
 * attempting to render it server-side and hydrate on the client.
 *
 * This is currently necessary because the Map needs a big refactor
 * and there are some underlying issues with the
 * `@react-google-maps/api` library that need further investigation.
 */
const ClientSideMap = dynamic(
  // @ts-expect-error prop mismatch with the dynamic import.
  () => {
    const theme = useTheme();
    if (theme.global.radar_maps) {
      return import("@/components/locations/map/radar/RadarMap").then(
        (component) => component.default
      );
    } else {
      return import("@/components/locations/map/google").then(
        (component) => component.default
      );
    }
  },
  { ssr: false }
);

class LocationsFinder extends Component<ReduxProps, State> {
  private androidGeolocationRequestTimeout:
    | ReturnType<typeof setTimeout>
    | undefined;

  state = {
    clickedMapSearch: false,
    hideMapButton: false,
    viewingAllLocations: false,
    handoffMode: CONVEYANCE_TYPES.PICKUP,
  };

  componentDidMount() {
    if (
      CONVEYANCE_TYPES[this.props.webConfig.global.default_conveyance_type] &&
      CONVEYANCE_TYPES[this.props.webConfig.global.default_conveyance_type] !==
        this.state.handoffMode
    ) {
      this.setState({
        handoffMode:
          CONVEYANCE_TYPES[this.props.webConfig.global.default_conveyance_type],
      });
    }

    if (isAndroidShell()) {
      try {
        // getGeolocation can fail if the Android app has not given geolocation permissions
        window.KoalaAndroidShell.getGeolocation();
        window.addEventListener(
          "android/geolocationUpdated",
          this.maybeReceivedAndroidGeoLocation as EventListener
        );
        this.androidGeolocationRequestTimeout = setTimeout(() => {
          // fallback to IP geolocation after a timeout
          if (this.props.webConfig.locations.geoip) {
            this.getNearbyLocations();
          }
        }, 5000);
      } catch (error) {
        ErrorReporter.captureException(error);
        // fallback to IP geolocation after an error
        if (this.props.webConfig.locations.geoip) {
          this.getNearbyLocations();
        }
      }
    } else {
      if (this.props.webConfig.locations.geoip) {
        this.getNearbyLocations();
      }
    }
  }

  componentDidUpdate(_prevProps: ReduxProps, prevState: State) {
    const { handoffMode } = this.state;

    if (prevState.handoffMode !== handoffMode) {
      genericEventHandler(GlobalEvents.DELIVERY__TOGGLE, {
        name: handoffMode,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearLocations();

    clearTimeout(this.androidGeolocationRequestTimeout);

    window.removeEventListener(
      "android/geolocationUpdated",
      this.maybeReceivedAndroidGeoLocation as EventListener
    );
  }

  maybeReceivedAndroidGeoLocation = (
    event: CustomEvent<{
      location: {
        lat?: number;
        lng?: number;
      };
    }>
  ) => {
    clearTimeout(this.androidGeolocationRequestTimeout);

    const { lat, lng } = event?.detail.location;

    // attempt to read the device latitude and longitude
    if (lat && lng) {
      genericEventHandler(GlobalEvents.LOCATION_RECOMMENDATIONS_SURFACED, {
        name: EventNames.LOCATIONS_SURFACED_GEOLOCATION,
      });

      this.onSearch({
        distance: LOCATION_OPTIONS.defaultSearchRadius,
        latitude: lat,
        longitude: lng,
      });
    } else {
      // otherwise start the normal ordering app process of using the customer's ip address
      this.getNearbyLocations();
    }
  };

  /** @TODO improve typing from the brand's `Map` component. */
  fetchCenterCoords = (mapCenterCoords: any, largestRadialDistance: any) => {
    this.onSearch({
      distance: largestRadialDistance,
      latitude: mapCenterCoords[0],
      longitude: mapCenterCoords[1],
    });

    this.setState({ clickedMapSearch: true });
  };

  getNearbyLocations = () => {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    this.setState({
      viewingAllLocations: false,
    });

    geoIpLookup({ client })
      .then((data: GeoIp) => {
        const geoData = data.geoData;

        if (geoData) {
          genericEventHandler(GlobalEvents.LOCATION_RECOMMENDATIONS_SURFACED, {
            name: EventNames.LOCATIONS_SURFACED_IPADDRESS,
          });

          this.onSearch({
            distance: LOCATION_OPTIONS.defaultSearchRadius,
            latitude: geoData.ll[0],
            longitude: geoData.ll[1],
          });
        } else {
          genericEventHandler(GlobalEvents.LOCATION_RECOMMENDATIONS_SURFACED, {
            name: EventNames.LOCATIONS_SURFACED_DEFAULT,
          });

          this.getLocations();
        }
      })
      .catch(() => this.getLocations());
  };

  // This controls the "Search this area" button visibility
  showMapButton = () => {
    if (this.state.hideMapButton) {
      this.setState({ hideMapButton: false });
    }
  };

  getLocations = (page?: number, viewAll?: boolean) => {
    const { fetchLocations, fetchAllLocations } = this.props;

    // Assign magicbox parameters
    const magicBox = new MagicBoxParams()
      .setPagination(page ?? 1, 50)
      .setSorts({ state_id: "asc", label: "asc" })
      .setIncludes([
        "operating_hours",
        "attributes",
        "delivery_hours",
        "business_hours",
      ]);

    if (viewAll) {
      fetchAllLocations(magicBox);
    } else {
      fetchLocations(magicBox);
    }

    this.setState({
      clickedMapSearch: false,
      hideMapButton: true,
    });
  };

  handleViewAll = () => {
    // clear any previous search results (to remove the search radius heading) before viewing all
    this.props.clearLocations();

    const groupedLocations =
      safelyGetConfig(this.props.webConfig, "locations.list_display") ===
      LOCATIONS_CONFIG.ALL_DISPLAY.GROUP_BY_STATE;
    // @ts-expect-error @TODO differentiate between `null` and `undefined`.
    this.getLocations(null, groupedLocations);

    this.setState({
      viewingAllLocations: true,
    });

    // Events
    genericEventHandler(GlobalEvents.LOCATIONS__VIEW_ALL_LOCATIONS);
  };

  onSearch = (values?: LocationSearchQuery, previousAction?: string) => {
    // Assign magicbox parameters
    const magicBox = new MagicBoxParams()
      .setPagination(1, 50)
      .setSorts({ state_id: "asc" })
      .setIncludes([
        "operating_hours",
        "attributes",
        "delivery_hours",
        "business_hours",
      ]);

    this.props.searchLocations(
      magicBox,
      values,
      previousAction,
      CONVEYANCE_TYPES.PICKUP
    );

    this.setState({
      clickedMapSearch: false,
      hideMapButton: true,
      viewingAllLocations: false,
    });
  };

  render() {
    const {
      data, // TODO: refers to me.data
      webConfig,
      activeLocationId,
      params,
      meta,
      loading,
      searchLoading,
      moreLocationsLoading,
      searchTimestamp,
      viewState,
      list,
      setActiveLocation,
      organization,
    } = this.props;

    const shouldShowLoyaltyAccessor =
      typeof this.props.webConfig.global.loyalty_accessor === "undefined"
        ? true
        : this.props.webConfig.global.loyalty_accessor;

    const { clickedMapSearch, hideMapButton, handoffMode } = this.state;

    const currentPage = get(meta, "pagination.current_page");

    // Optional Delivery Search
    const deliverySearchEnabled = webConfig.locations.delivery_search;
    const me = data;

    const isLocationListEmpty = !list || !list.length;
    const showViewAllCTA =
      viewState !== LOCATION_VIEW_STATES.VIEW_ALL && // Not the view all state.
      !this.state.viewingAllLocations &&
      !loading &&
      !searchLoading && // is not in a loading state
      (!params || (Boolean(params) && list && list.length > 0)); // Is not in a state where the search returned empty. That is because the other load cta button will show up in that case.

    const MapComponent = (
      <ClientSideMap
        // @ts-expect-error prop mismatch with the dynamic import.
        activeLocationId={activeLocationId}
        locations={list}
        setActiveLocation={setActiveLocation}
        locationSearchTimestamp={searchTimestamp}
        fetchCenterCoords={this.fetchCenterCoords}
        hideMapButton={hideMapButton}
        showMapButton={this.showMapButton}
      />
    );

    return (
      <Layout
        disabled={webConfig.locations.disable_locations_page}
        pageName="locations"
      >
        <div>
          <LocationsLayout>
            <LocationsLayoutList
              className={CSS_CLASSES.LOCATION_LIST.CONTAINER}
            >
              {me?.id ? (
                <StringAccessor
                  tag="h1"
                  accessor="locations.header_user"
                  html={true}
                  dataObj={me}
                />
              ) : (
                <StringAccessor
                  tag="h1"
                  accessor="locations.header_guest"
                  html={true}
                />
              )}

              {/* Points and Rewards summary on locations page */}
              {shouldShowLoyaltyAccessor && (
                <LoyaltyAccessor
                  checkType={LOYALTY_ACCESSOR_TYPES.FEATURE}
                  checkName={LOYALTY_FEATURES.GET_AVAILABLE_REWARDS}
                  component={<LocationsRewardsSummary />}
                />
              )}

              <FeatureAccessor
                featureFlag={FEATURE_FLAGS.ME__REORDER}
                renderFallback={
                  <div style={{ margin: "20px 0 0" }}>
                    <StringAccessor
                      tag="div"
                      accessor="locations.subheader"
                      html={true}
                    />
                  </div>
                }
              >
                <LocationsReorders>
                  <ReorderList ordersToDisplay={5} />
                </LocationsReorders>

                <NewOrderHeading />
              </FeatureAccessor>

              {/* Optional Delivery Search Toggle */}
              <Render condition={deliverySearchEnabled}>
                <LocationsHandoffToggle
                  aria-label="Handoff Toggle"
                  role="tablist"
                  handoffMode={handoffMode}
                  style={{ marginTop: LAYOUT.GUTTER }}
                  className={CSS_CLASSES.LOCATION_LIST.HANDOFF_TOGGLE}
                >
                  <LocationsHandoffToggleButton
                    aria-selected={handoffMode === CONVEYANCE_TYPES.PICKUP}
                    role="tab"
                    aria-controls="non-delivery-tab"
                    active={handoffMode === CONVEYANCE_TYPES.PICKUP}
                    onClick={() =>
                      this.setState({ handoffMode: CONVEYANCE_TYPES.PICKUP })
                    }
                  >
                    <StringAccessor
                      accessor="locations.search_tab_default_text"
                      html={true}
                      tag="span"
                    />
                  </LocationsHandoffToggleButton>
                  <LocationsHandoffToggleButton
                    aria-selected={handoffMode === CONVEYANCE_TYPES.DELIVERY}
                    role="tab"
                    aria-controls="delivery-tab"
                    active={handoffMode === CONVEYANCE_TYPES.DELIVERY}
                    onClick={() =>
                      this.setState({ handoffMode: CONVEYANCE_TYPES.DELIVERY })
                    }
                  >
                    <StringAccessor
                      accessor="locations.search_tab_delivery_text"
                      html={true}
                      tag="span"
                    />
                  </LocationsHandoffToggleButton>
                </LocationsHandoffToggle>
              </Render>

              {/* Delivery Search */}
              <Render
                condition={
                  handoffMode === CONVEYANCE_TYPES.DELIVERY &&
                  deliverySearchEnabled
                }
              >
                <div role="tabpanel" id="delivery-tab">
                  <LocationsDeliverySearch>
                    <DeliverySearch />
                  </LocationsDeliverySearch>
                </div>
              </Render>

              <Render condition={handoffMode !== CONVEYANCE_TYPES.DELIVERY}>
                <div role="tabpanel" id="non-delivery-tab">
                  {/* Normal List-View / Pickup Search */}
                  <FixOnScroll
                    fromTop={LAYOUT.MOBILE_HEADERHEIGHT}
                    id="LocationSearch"
                    minWidth={767}
                  >
                    <Search
                      onSearch={this.onSearch}
                      getNearbyLocations={this.getNearbyLocations}
                      onReset={this.handleViewAll}
                      params={params}
                      loading={searchLoading}
                      showViewAll={showViewAllCTA}
                      initialValues={{
                        distance: LOCATION_OPTIONS.defaultSearchRadius,
                      }}
                      locationViewState={viewState}
                      handoffMode={handoffMode}
                      organization={organization}
                      isLocationListEmpty={isLocationListEmpty}
                      isGeoIpEnabled={this.props.webConfig.locations.geoip}
                    />
                  </FixOnScroll>

                  <Render condition={searchLoading || loading}>
                    <div>
                      {/* @ts-expect-error missing children prop annotation */}
                      <LoadingIndicator className="pad">
                        Searching for locations...
                      </LoadingIndicator>
                    </div>
                  </Render>

                  {/* Locations message derived from location data in this.deriveResults() */}
                  <LocationsHeading
                    onSearch={this.onSearch}
                    isLoading={searchLoading}
                    locationViewState={viewState}
                    locations={list}
                    // @ts-expect-error @TODO ensure that `params` are not null.
                    locationParams={params}
                    locationsMeta={meta}
                    clickedMapSearch={clickedMapSearch}
                  />

                  {/* Display a user's favorite locations
                    - If a user is logged in
                    - And has favorited locations
                    - And is not in the middle of an active search */}
                  {/* Default toggle view only */}
                  <LayoutListSection data-testid="locators-list-section">
                    {/* User or Geolocation search status messages */}
                    <Render condition={searchLoading}>
                      <LocationsNoResults>
                        Searching for {pluralizeString(organization.label)} in
                        your area.
                      </LocationsNoResults>
                    </Render>

                    <Render
                      condition={Boolean(
                        params && list && !list.length && !searchLoading
                      )}
                    >
                      <LocationsNoResults>
                        <div style={{ paddingBottom: `${LAYOUT.GUTTER}px` }}>
                          <StringAccessor
                            accessor="locations.no_results"
                            html={true}
                            dataObj={{ brandName: organization.label }}
                          />
                        </div>

                        <LocationsViewAll onClick={this.handleViewAll}>
                          <StringAccessor
                            accessor="locations.all_locations_cta"
                            className={
                              CSS_CLASSES.STORE_LOCATOR.VIEW_ALL_LOCATION_LINK
                            }
                          />
                        </LocationsViewAll>
                      </LocationsNoResults>
                    </Render>

                    {/* Consolidated Results List */}
                    <ResultsList
                      // @ts-expect-error @TODO ensure that `activeLocationId` isn't null.
                      activeLocationId={activeLocationId}
                      locations={list}
                      setActiveLocation={setActiveLocation}
                      displayType={viewState}
                    />

                    <Render condition={moreLocationsLoading}>
                      <div>
                        {/* @ts-expect-error missing children prop annotation */}
                        <LoadingIndicator>
                          Loading more locations...
                        </LoadingIndicator>
                      </div>
                    </Render>

                    <Render
                      condition={Boolean(
                        !moreLocationsLoading &&
                          meta &&
                          meta.pagination &&
                          currentPage < meta.pagination.total_pages
                      )}
                    >
                      <LocationsViewAll
                        onClick={() => this.getLocations(currentPage + 1)}
                      >
                        View More Locations
                      </LocationsViewAll>
                    </Render>
                  </LayoutListSection>
                </div>
              </Render>
            </LocationsLayoutList>

            {/* Map (found at: `apps/web-ordering/src/components/locations/map/google/index.tsx`) */}
            <LocationsLayoutMap>
              {/* Render map only if we're on desktop... */}
              {typeof window !== "undefined" &&
                !window.matchMedia(`(max-width: 767px)`).matches && (
                  <>{MapComponent}</>
                )}
            </LocationsLayoutMap>
          </LocationsLayout>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  organization: state.app.organization.organization,
  activeLocationId: state.app.locations.activeLocationId,
  params: state.app.locations.params,
  meta: state.app.locations.meta,
  loading: state.app.locations.loading,
  searchLoading: state.app.locations.searchLoading,
  moreLocationsLoading: state.app.locations.moreLocationsLoading,
  searchTimestamp: state.app.locations.searchTimestamp,
  viewState: state.app.locations.viewState,
  list: state.app.locations.list,
  webConfig: state.app.cmsConfig.webConfig,
  data: state.app.me.data,
});

const mapDispatchToProps = {
  fetchLocations: locationsActions.fetchLocations,
  fetchAllLocations: locationsActions.fetchAllLocations,
  searchLocations: locationsActions.searchLocations,
  setActiveLocation: locationsActions.setActiveLocation,
  clearLocations: locationsActions.clearLocations,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default compose(connector, withRouter)(LocationsFinder);
